@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@400;600&display=swap');

$lightgray: #ccc;
$darkgray: #333;
$white: #fff;
$purple: #9883e5;
$yellow: #fff0e2;

.app {
  width: 100%;
  font-family: 'Martel Sans', sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: $darkgray;
  background-color: $white;
}

header {
  padding: 0 1.5em;
  border-bottom: 1px solid $darkgray;
  h1 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    font-weight: 400;
  }
}

a {
  color: $purple;
}

.artist-card {
  flex: 1;
  margin: 1em;
  overflow: hide;
  background-color: $white;
  border-radius: 6px;
  border: 1px solid $lightgray;
  cursor: pointer;
  &.selected {
    border-color: $darkgray;
    cursor: initial;
  }
}

.artist-thumb {
  height: 200px;
  padding: 0 0.35em;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 0.9em;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.artist-thumb::-webkit-scrollbar {
  display: none;
}

.artist-info {
  padding: 1em 1em 3em 1em;
  line-height: 1.35em;
  word-break: break-word;
}

.artist-return-btn {
  position: absolute;
  bottom: 0;
  padding: 0.25em 0.5em 0 0.75em;
  background-color: $white;
  font-size: 1.25em;
  cursor: pointer;
}

.artist-heading {
  padding: 0.6em 0.9em 0 0.9em;
  border-top: 1px solid $lightgray;
}

.artist-name {
  font-size: 1.2em;
  font-weight: 600;
}

.artist-tags {
  height: 101px;
  font-size: 0.75em;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.artist-tags::-webkit-scrollbar {
  display: none;
}

.artist-tag {
  display: inline-block;
  margin: 0 8px 6px 0;
  padding: 2.75px 8px 1px 8px;
  background-color: $yellow;
}

.grid-scrollview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background-color: $white;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.grid-scrollview::-webkit-scrollbar {
  display: none;
}

.grid {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4.2em 1em;
}

.grid-item {
  display: flex;
  flex-basis: 25%;
}

.loading {
  padding: 8em 2em;
  display: flex;
  justify-content: center;
}

.main {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: $white;
}

.no-results {
  margin-top: 3em;
  margin-left: 2em;
  font-size: 1.5em;
}

.search-container {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-width: 0 0 1px 0;
  border-color: $darkgray;
  border-style: solid;
  z-index: 1;
}

.search-input {
  flex: 1;
  padding: 0.5em 1em 0.3em 1.2em;
  font-family: 'Martel Sans', sans-serif;
  font-size: 1.2em;
  border: 0;
  background-color: $white;
}

.search-clear-icon {
  display: flex;
  padding: 1em;
  cursor: pointer;
}

.site-description {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.85em;
  line-height: 1.6;
  text-align: right;
  div {
    width: 560px;
    padding: 4em 2em 2em 1.25em;
  }
  a {
    color: $darkgray;
  }
}

.tags-scrollview {
  width: 200px;
  background-color: $white;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tags-scrollview::-webkit-scrollbar {
  display: none;
}

.tags-container {
  margin-bottom: 15em;
}

.tags-header {
  display: flex;
  margin-top: 4.25em;
  padding: 0 0 0.4em 1.5em;
  font-weight: 600;
  border-bottom: 1px solid $darkgray;
}

.tag {
  display: block;
  padding: 0.55em 1em 0.25em 1.5em;
  cursor: pointer;
  background-color: $white;
  border: 1px solid $white;
  &.selected {
    background-color: $yellow;
    border: 1px solid $yellow;
  }
}

/* MEDIA QUERIES */

@media screen and (max-width: 1500px) {
  .grid-item {
    flex-basis: 33.333333%;
  }
}

@media screen and (max-width: 1100px) {
  .grid-item {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 800px) {
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    h1 {
      margin: 0;
      font-size: 1.5em;
    }
  }
  .artist-card {
    height: 119px;
    display: flex;
    margin-bottom: 0;
    border-color: $darkgray;
    border-radius: 0;
  }
  .artist-thumb {
    width: 70px;
    height: 119px;
    margin-right: 5px;
    padding: 0;
    border-radius: 0;
  }
  .artist-heading {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.5em 0.5em 0 0.5em;
    border: 0;
  }
  .artist-name {
    font-size: 1em;
    font-weight: 600;
  }
  .artist-tags {
    flex: 1;
    font-size: 0.7em;
  }
  .artist-tag {
    padding: 2px 6px 0 6px;
  }
  .artist-selected-mobile {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    .artist-selected-inner {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 1em 1em 1em;
      border: 1px solid $darkgray;
      overflow-y: scroll;
    }
    .artist-selected-info {
      flex: 1;
      padding: 1em;
      border-top: 1px solid $darkgray;
      line-height: 1.35em;
      word-break: break-word;
      overflow-y: scroll;
    }
    .artist-selected-name {
      margin-top: 0.5em;
      font-size: 1.25em;
      font-weight: 600;
    }
    .artist-selected-return {
      padding: 0.75em 1em 0.5em 1em;
    }
  }
  .grid {
    margin: 104px 0 16px 0;
    padding: 0;
  }
  .grid-item {
    flex-basis: 100%;
  }
  .no-results {
    flex: 1;
    margin-left: 0;
    font-size: 1em;
    text-align: center;
  }
  .search-container {
    width: 100%;
    margin-top: 51px;
    background-color: $white;
    border-color: $darkgray;
  }
  .search-input {
    padding: 12px 0 8px 16px;
    font-size: 16px;
  }
  .site-description {
    font-size: 0.75em;
    line-height: 1.5;
    text-align: left;
    div {
      width: 100%;
    }
  }
  .tag-toggle-mobile {
    position: fixed;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    background-color: $white;
    font-size: 16px;
    font-weight: 600;
    z-index: 1;
    border-bottom: 1px solid $darkgray;
    cursor: pointer;
  }
  .tags-scrollview {
    width: 100%;
    padding: 0;
  }
  .tags-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12em;
  }
  .tags-header {
    width: 100%;
    justify-content: center;
    padding: 13px 0 7px 0;
    margin-top: 51px;
    border-color: $darkgray;
  }
  .tag-wrapper {
    flex-basis: 33.333333%;
  }
  .tag {
    height: 4.65em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4em 0.4em 0 0.4em;
    font-size: 0.8em;
    text-align: center;
    line-height: 1.5em;
  }
}